import {useState, useEffect} from "react";

type CookiePreferencesValue = {
  "strictly necessary": boolean;
  functionality: boolean;
  performance: boolean;
  targeting: boolean;
};

const defaultCookiePreferences: CookiePreferencesValue = {
  "strictly necessary": false,
  functionality: false,
  performance: false,
  targeting: false,
};

export function useCookiesPreferences() {
  const [preferences, setPreferences] = useState<CookiePreferencesValue>(defaultCookiePreferences);

  useEffect(() => {
    const getCookiesPreferences = () => {
      const cookieStringList = document.cookie.split(";") || [];
      const cookieEntries = cookieStringList.map((cookieString) => {
        const [key, value] = cookieString.trim().split("=");
        return [key, decodeURIComponent(value)];
      });
      const cookies = Object.fromEntries(cookieEntries);
      const preferencesAsString: string = cookies["cookie-configuration"];
      // eslint-disable-next-line no-shadow
      const preferences = preferencesAsString ? JSON.parse(preferencesAsString) : {value: defaultCookiePreferences};
      setPreferences(preferences.value);
    };

    getCookiesPreferences();
  }, []);

  return preferences;
}
