import FinApp from "src/finapp";
import {useDynaTraceAnalyticsHandler, AuthConstants, AuthGuard, IDPType} from "@fce-bees/fce-common-components";
import {applicationId, imsBaseUrl} from "./lib/constants";
import {AuthConfig} from "@fce-bees/fce-common-components/src/components/auth-cookie/cookie-auth-service";
import {getMarketLanguage} from "./components/organisms/linear-application/utils";
import {useCookiesPreferences} from "src/hooks/use-cookie-preferences";

const {marketLanguage} = getMarketLanguage();

const disableAuth = import.meta.env.VITE_FINAPP_DISABLE_AUTH === "true";

const aadTenantUrl = `${import.meta.env.VITE_AAD_BASE_URL}${import.meta.env.VITE_AAD_POLICY_ID}${marketLanguage}${import.meta.env.VITE_ADD_OAUTH_PATH}`;

if (disableAuth) {
  const expiry = new Date().setFullYear(new Date().getFullYear() + 1);
  document.cookie = `${AuthConstants.authCookieName}=cd069c20-ba53-4132-8905-5f3290f11c81; expires=${expiry}; path=/`;
}

const authConfig: AuthConfig = {
  baseAuthorizationURL: aadTenantUrl,
  idpClientId: import.meta.env.VITE_CLIENT_ID,
  redirectURI: `${import.meta.env.VITE_FINAPP_IMS_BASE_URL}${import.meta.env.VITE_IMS_AAD_EXCHANGE_URL}`,
  responseMode: "form_post",
  resource: import.meta.env.VITE_APP_ID,
};

const App = () => {
  const cookiePreferences = useCookiesPreferences();
  useDynaTraceAnalyticsHandler({
    src: import.meta.env.VITE_AM_DYNA_TRACE_SCRIPT_SRC,
    trackCookies: cookiePreferences.performance,
  });

  return (
    <AuthGuard
      authConfig={authConfig}
      applicationId={applicationId}
      imsBaseUrl={imsBaseUrl}
      authType="COOKIE"
      cookieDomain=".ford.com"
      errorRoute="/error"
      idpType={IDPType.aad}
      market={marketLanguage}
      processId={crypto.randomUUID()}
    >
      <FinApp />
    </AuthGuard>
  );
};

export default App;
